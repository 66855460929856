@import "https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&display=swap";
@import url('https://fonts.googleapis.com/css2?family=Lato&family=Lora:wght@700&family=Yeseva+One&display=swap');

:root {
  --main-font-family: 'Lato', sans-serif;
  --main-font-color: #424242;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.website-header {
  display: flex;
  flex-direction: row;
  margin-top: -1.5rem;
}

.website-logo {
  margin: 1.3rem;
  height: 3.5rem;
}

.website-name {
  font-family: 'Lora', serif;
  color: #282c34;
}

.nav-bar {
  justify-content: left !important;
  background-color: rgb(246, 246, 246) !important;
  padding: 1rem;
}

.MuiBottomNavigationAction-label {
  font-size: 1.25rem !important;
}

.page-card {
  width: 70%;
  text-align: center;
  margin-top: 5%;
  margin-bottom: 5%;
  padding: 2%;
  padding-bottom: 5%;
}

.page-title {
  width: 85%;
  text-align: center;
  margin-bottom: 20px;
}

.centered-content {
  margin-left: auto !important;
  margin-right: auto !important;
  display: flex !important;
  flex-direction: column;
}

.home-content {
  width: 65%;
  text-align: left;
}

.demo-component {
  width: 65% !important;
  margin-top: 10px !important;
  margin-bottom: 25px !important;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.wide-demo-component {
  width: 90% !important;
  font-size: 14px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

BottomNavigation:hover {
background-color: blue;
}
h1{
  /* font-family: "Josefin Sans";
  color: #2A4474; */
  font-family: var(--main-font-family);
  color: var(--main-font-color);
  font-weight: 900;
  font-size: 2.5rem;
  margin-bottom:10px;

}
h2{
  /* font-family: "Josefin Sans";
  color: #2A4474; */
  font-family: var(--main-font-family);
  color: var(--main-font-color);
  font-weight: 600;
  font-size: 2rem;
  margin-left: 10px;
  margin-bottom:10px;

}
h3{
  font-family: var(--main-font-family);
  color: var(--main-font-color);
  font-size: 1.7rem;
  margin-left: 10px;
  margin-bottom:10px;
}
h4{
  font-family: var(--main-font-family);
  font-weight: 500;
  color: var(--main-font-color);
  font-size: 1.4rem;
  margin-left: 10px;
  margin-bottom:10px;
}
p{
  font-family: "Lato";
  /* color: #6482b8; */
  color: #585858;
  font-size: 1.25rem;
  font-weight: 500;
  margin-left: 10px;
  margin-bottom: 5px;
}
div{
  /* color:#7b90b7; */
 
}
a{
  font-family: "Lato";
  color: #6482b8;
  margin-left: 10px;
  margin-bottom:10px;
  font-weight:bolder;

}

Container{
  background-color: #2A4474;
}

.input-card {
  padding: 3% !important;
  background-color: #3d626a !important;
}

.story-input {
  display: flex !important;
  justify-content: center;
  width: 100% !important;
  margin-bottom: 20px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.MuiOutlinedInput-root {
  border-radius: 0 !important;
}

.submit-button {
  width: 200px;
  justify-content: center;
  margin-top: 30px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  display: flex !important;
  color: #424242 !important;
  background-color: #dddddd !important;
}

.loading-spinner {
  margin-top: 100px !important;
}

.box-section {
  background-color: #ffffff !important;
  padding: 10px 30px 40px 30px;
  margin: 20px;
  border-radius: 10px !important;
}

.inline-button {
  margin-left: 15px !important;
}

.utility-button {
  background-color: #648ab5 !important;
}

.cancel-button {
  background-color: #b57070 !important;
}

.editable-value-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 15px !important;
}

.lighter-text {
  color: #939393 !important;
  font-style: italic;
}

.user-input {
  margin-top: 40px;
  border: 5px solid white;
  border-radius: 10px;
  margin-left:auto;
  margin-right: auto;
  /* -webkit-box-shadow:0 0 0 30px #acc5f0; */
  width: 80%;
}

.user-input-content-section {
  background-color: white;
  border-radius: 5px;
  padding: 1% 4% 4% 4%;
}

.one-line-input {
  width: 100% !important;
  margin: 10px !important;
}

.story-accordion {
  width: 90% !important;
  background-color: #f4f4f4 !important;
  margin-bottom: 10px !important;
}

.alerts {
  margin: 10px !important;
}

.sbutton {
  margin-top:-16px;
}

.header{
  text-align: center;
}
hr{
  border: .5px solid rgb(202, 202, 215);
}

.response{
  margin-top: 40px;

  border: 5px solid white;
  -webkit-box-shadow:0 0 0 30px #acc5f0;
  width: 1300px;
  border-radius: 10px;
  margin-left:auto;
  margin-right: auto;
  padding: 10px;
}